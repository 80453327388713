import { useCallback, useEffect, useRef, useState } from "react";
import {
    allEventNamesList,
    GuidedEvent,
    GuidedEventAction,
    GuidedEventObject,
    GuidedEventUserPropType,
} from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { useUserAlertsSelector } from "@/hooks/guidedEvents/UserAlerts";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { isEqual } from "lodash";

const useGuidedEvent = (events: GuidedEvent) => {
    const { user } = useCurrentUser();

    const userAlerts = useUserAlertsSelector(state => state.userAlerts);
    const allStagedEvents = useUserAlertsSelector(state => state.allStagedEvents);

    const refreshUserAlerts = useUserAlertsSelector(state => state.refreshUserAlerts);
    const removeFromDone = useUserAlertsSelector(state => state.removeFromDone);
    const addTodoEvent = useUserAlertsSelector(state => state.addTodoEvent);
    const closeEvent = useUserAlertsSelector(state => state.closeEvent);
    const decrementVisitCount = useUserAlertsSelector(state => state.decrementVisitCount);
    const removeFromTodo = useUserAlertsSelector(state => state.removeFromTodo);
    const setAllStagedEvents = useUserAlertsSelector(state => state.setAllStagedEvents);

    const [initialLoadUpDone, setInitialLoadUpDone] = useState(false);
    const [errorsChecked, setErrorsChecked] = useState(false);
    const [userAlertsRefreshed, setUserAlertsRefreshed] = useState(false);

    const [stagedEvents, setStagedEvents] = useState<GuidedEventObject[]>();

    const cachedUserAlerts = useRef(userAlerts);

    const lookForEventsToShow = useCallback(() => {
        events?.forEach(event => {
            const visitCount = event.visitCount ?? 0;

            const alreadyExistsInDone = userAlerts?.done?.includes?.(event.eventName);
            const alreadyExistsInTodo = userAlerts?.todo?.map(event => event.eventName)?.includes?.(event.eventName);

            const hasUserProp = !!event.userProp;

            setStagedEvents(prev =>
                prev?.filter(upcomingEvent => !userAlerts?.done?.includes?.(upcomingEvent.eventName))
            );

            setAllStagedEvents(prev => prev?.filter(eventName => !userAlerts?.done?.includes?.(eventName)));

            if (hasUserProp) {
                const shouldAddToDone =
                    event.userProp.type === GuidedEventUserPropType.LESS
                        ? user?.created > event.userProp.created
                        : user?.created <= event.userProp.created;

                if (shouldAddToDone) {
                    if (!alreadyExistsInDone) {
                        return closeEvent(event.eventName);
                    }

                    return;
                }
                // more conditions can be added here in the future...
            }

            if (visitCount === 0 && !alreadyExistsInDone) {
                if (event.action === GuidedEventAction.REMOVE) return removeFromDone(event.eventName);
                return setStagedEvents(prev => [...new Set([...(prev ?? []), event])]);
            }

            if (visitCount > 0 && !alreadyExistsInTodo && !alreadyExistsInDone) {
                return addTodoEvent(event);
            }
        });
    }, [
        addTodoEvent,
        closeEvent,
        events,
        removeFromDone,
        setAllStagedEvents,
        user?.created,
        userAlerts?.done,
        userAlerts?.todo,
    ]);

    useEffect(() => {
        if (!initialLoadUpDone && userAlerts) {
            setInitialLoadUpDone(true);
        }

        if (!errorsChecked) {
            const allEventNamesSet = new Set(allEventNamesList);
            const allEventNamesListHasDuplicates = allEventNamesSet.size !== allEventNamesList.length;
            if (allEventNamesListHasDuplicates) {
                const duplicateEvents = allEventNamesList.filter(
                    (eventName, index, self) => self.indexOf(eventName) !== index
                );

                throw new Error(`Duplicate event names found in GUIDED_EVENTS : ${duplicateEvents}`);
            }
            setErrorsChecked(true);
        }

        if (initialLoadUpDone && !userAlertsRefreshed) {
            const { eventsToStage, eventsToDecrementVisitCount, eventsToRemoveFromDone, eventsToRemoveFromTodo } =
                refreshUserAlerts({ events });

            eventsToStage.forEach(event => setStagedEvents(prev => [...new Set([...(prev ?? []), event])]));
            eventsToDecrementVisitCount.forEach(event => decrementVisitCount(event));
            eventsToRemoveFromDone.forEach(event => removeFromDone(event));
            eventsToRemoveFromTodo.forEach(event => removeFromTodo(event));

            setUserAlertsRefreshed(true);
        }

        if (
            initialLoadUpDone &&
            errorsChecked &&
            userAlertsRefreshed &&
            !isEqual(cachedUserAlerts.current, userAlerts)
        ) {
            cachedUserAlerts.current = userAlerts;
            lookForEventsToShow();
        }

        const stagedEventsExistInAllStagedEvents = stagedEvents?.every(event =>
            allStagedEvents.includes(event.eventName)
        );

        if (stagedEvents?.length && !stagedEventsExistInAllStagedEvents) {
            const eventNames = stagedEvents?.map(event => event.eventName);

            setAllStagedEvents(prev => [...new Set([...(prev ?? []), ...(eventNames ?? [])])]);
        }
    }, [
        allStagedEvents,
        decrementVisitCount,
        errorsChecked,
        events,
        initialLoadUpDone,
        lookForEventsToShow,
        refreshUserAlerts,
        removeFromDone,
        removeFromTodo,
        setAllStagedEvents,
        stagedEvents,
        userAlerts,
        userAlertsRefreshed,
    ]);

    const closeLatestEvent = useCallback(() => {
        if (!stagedEvents.length) return;

        const latestEvent = stagedEvents[0];

        if (latestEvent) closeEvent(latestEvent.eventName);
    }, [closeEvent, stagedEvents]);

    return {
        stagedEvents,
        allStagedEvents,
        latestEvent: stagedEvents?.[0],
        closeLatestEvent,
        closeEvent,
    };
};

export default useGuidedEvent;
