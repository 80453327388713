"use client";

import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { SIDEBAR_ADS_CONTAINER_ID } from "../constants";
import { SidebarAdSlot } from "./FreestarAdSlot";
import { Organization, UserDetails } from "@knowt/syncing/graphql/schema";
import br from "@/styles/breakpoints.module.css";
import { spacing } from "@/utils/spacing";
import { canShowAds } from "@knowt/syncing/hooks/user/subscriptions";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";

// TODO: maybe refactor this to take in LocalUser object instead
const MainContainerAdSlot = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}) => {
    const { lgDown } = useBreakPoints();

    if (!canShowAds({ user, organization })) return null;
    if (lgDown) return null;

    return (
        <div
            id={SIDEBAR_ADS_CONTAINER_ID}
            className={br.lgDownDisplayNone}
            style={{
                alignSelf: "flex-start",
                position: "sticky",
                top: `calc(var(--navbar-height) + ${spacing.LG})`,
                minWidth: 320,
                minHeight: 620,
                width: 320,
                height: 620,
                marginLeft: "2.8rem",
            }}>
            <SidebarAdSlot />
        </div>
    );
};

/**
 * This can be used if the user is not available at render time.
 */
export const FallbackMainContainerAdSlot = () => {
    const { user, organization } = useCurrentUser();

    return <MainContainerAdSlot user={user} organization={organization} />;
};

export default MainContainerAdSlot;
