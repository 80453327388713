"use client";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
// import Image from "next/image";
import React, { useEffect, useMemo } from "react";
// import Link from "@/components/wrappers/Link";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
// import { useDetectAdBlock } from "@/hooks/useDetectAdBlocker";
import { ASSETS_URL } from "@/config/deployConstants";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";
import { themeColors } from "@/utils/themeColors";
import { useDetectAdBlock } from "@/hooks/useDetectAdBlocker";
import Link from "@/components/wrappers/Link";
import Image from "next/image";
import { AdSize, AdSlotId, Platform } from "../types";
import { FREESTAR_PUBLISHER } from "../constants";

const ad320x50_AI = `${ASSETS_URL}/images/adblocker/320x50 AI.png`;
const ad320x50_AP = `${ASSETS_URL}/images/adblocker/320x50 AP.png`;
const ad300x600_AI = `${ASSETS_URL}/images/adblocker/300x600 AI.png`;
const ad300x600_AP = `${ASSETS_URL}/images/adblocker/300x600 AP.png`;
const ad300x250_AI = `${ASSETS_URL}/images/adblocker/300x250 AI.png`;
const ad728x90_AI = `${ASSETS_URL}/images/adblocker/728x90 AI.png`;
const ad728x90_AP = `${ASSETS_URL}/images/adblocker/728x90 AP.png`;
const ad320x100_AI = `${ASSETS_URL}/images/adblocker/320x100 AI.png`;

const AD_SIZES_BY_ID: Readonly<Record<AdSlotId, Record<Platform, AdSize>>> = {
    // Footer Ad slot, has no parent
    knowt_sticky_footer: {
        mobile: {
            adBlockerFallback: [],
        },
        tablet: {
            adBlockerFallback: [],
        },
        desktop: {
            adBlockerFallback: [],
        },
    },

    // Sidebar ad slot
    // <!-- FREESTAR D: 300x250,160x600,300x600 T: 300x250,160x600,300x600 M: 300x250,320x50,320x100 -->
    knowt_sticky_rightrail: {
        mobile: {
            width: 320,
            height: 250,
            adBlockerFallback: [
                {
                    image: ad320x50_AI,
                    href: "/plans",
                },
                {
                    image: ad320x50_AP,
                    href: "/exams/ap",
                },
                {
                    image: ad320x100_AI,
                    href: "/plans",
                },
                {
                    image: `${ASSETS_URL}/images/adblocker/320x100 AP.png`,
                    href: "/exams/ap",
                },
            ],
        },
        tablet: {
            width: 300,
            height: 600,
            adBlockerFallback: [
                {
                    image: ad300x600_AI,
                    href: "/plans",
                },
                {
                    image: ad300x600_AP,
                    href: "/exams/ap",
                },
            ],
        },
        desktop: {
            width: 300,
            height: 600,
            adBlockerFallback: [
                {
                    image: ad300x600_AI,
                    href: "/plans",
                },
                {
                    image: ad300x600_AP,
                    href: "/exams/ap",
                },
            ],
        },
    },

    // <!-- FREESTAR Repeatable leaderboard ad - to be placed between sections D: 728x90 T: 728x90 M: 320x50,320x100  START -->
    knowt_dynamic_incontent: {
        mobile: {
            width: 320,
            height: 100,
            adBlockerFallback: [
                {
                    image: ad320x100_AI,
                    href: "/plans",
                },
                {
                    image: `${ASSETS_URL}/images/adblocker/320x100 AP.png`,
                    href: "/exams/ap",
                },
            ],
        },
        tablet: {
            width: 728,
            height: 90,
            adBlockerFallback: [
                {
                    image: ad728x90_AI,
                    href: "/plans",
                },
                {
                    image: ad728x90_AP,
                    href: "/exams/ap",
                },
            ],
        },
        desktop: {
            width: 728,
            height: 90,
            adBlockerFallback: [
                {
                    image: ad728x90_AI,
                    href: "/plans",
                },
                {
                    image: ad728x90_AP,
                    href: "/exams/ap",
                },
            ],
        },
    },

    // <!-- FREESTAR Video Ad Slot. Sizes are scaled versions of 640x480
    knowt_primis: {
        mobile: {
            width: 320,
            height: 100,
            adBlockerFallback: [
                {
                    image: ad320x100_AI,
                    href: "/plans",
                },
            ],
        },
        tablet: {
            width: 300,
            height: 250,
            adBlockerFallback: [
                {
                    image: ad300x250_AI,
                    href: "/plans",
                },
            ],
        },
        desktop: {
            width: 640,
            height: 480,
            adBlockerFallback: [
                {
                    image: ad728x90_AI,
                    href: "/plans",
                },
            ],
        },
    },
};

const GenericAdSlot = ({
    placement,
    slotId,
    classes = [],
}: {
    placement: AdSlotId;
    slotId?: number;
    classes?: string[];
}) => {
    const { smDown, mdDown } = useBreakPoints();
    const { isSubscriptionActive } = useCurrentUser();

    const adBlockDetected = useDetectAdBlock();
    const deviceType = smDown ? "mobile" : mdDown ? "tablet" : "desktop";
    const { width, height, adBlockerFallback: _adBlockerFallback } = AD_SIZES_BY_ID?.[placement]?.[deviceType] || {};
    const fallbackIndex = useMemo(() => Math.floor(Math.random() * _adBlockerFallback.length), [_adBlockerFallback]);
    const adBlockerFallback = _adBlockerFallback[fallbackIndex];

    const showKnowtAds = adBlockDetected && !!adBlockerFallback;

    if (isSubscriptionActive) {
        return null;
    }

    // const targeting = { key1: "value1", key2: "value2" };

    const adDiv = (
        <FreestarAdSlot
            publisher={FREESTAR_PUBLISHER}
            placementName={placement}
            // unique ID
            slotId={slotId ? `${placement}_${slotId}` : placement}
            // targeting={targeting}
            channel="custom_channel"
            classList={classes}
            onNewAdSlotsHook={placementName => {
                // eslint-disable-next-line no-console
                console.log("creating ad", placementName);
            }}
            onDeleteAdSlotsHook={placementName => {
                // eslint-disable-next-line no-console
                console.log("destroying ad", placementName);
            }}
            onAdRefreshHook={placementName => {
                // eslint-disable-next-line no-console
                console.log("refreshing ad", placementName);
            }}
        />
    );

    if (!width || !height) {
        return adDiv;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: __DEV__ || showKnowtAds ? themeColors.neutral1 : "transparent",
                width: width + 20,
                height: height + 20,
            }}>
            {showKnowtAds ? (
                <Link href={adBlockerFallback.href} rel={"noopener noreferrer"}>
                    <Image src={adBlockerFallback.image} alt={"adblock-recovery"} height={height} width={width} />
                </Link>
            ) : (
                adDiv
            )}
        </div>
    );
};

export const HorizontallyCenteredRepeatableLeaderboardAdSlot = ({
    className,
    containerStyle = {},
    slotId = undefined,
}: {
    className?: string;
    containerStyle?: React.CSSProperties;
    slotId?: number;
} = {}) => {
    const { canShowAds } = useCurrentUser();

    if (!canShowAds) return null;

    return (
        <div
            className={className}
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                ...containerStyle,
            }}>
            {/*// <!-- FREESTAR Repeatable leaderboard ad - to be placed between sections D: 728x90 T: 728x90 M: 320x50,320x100  START -->*/}
            <GenericAdSlot placement={"knowt_dynamic_incontent"} slotId={slotId} />
        </div>
    );
};

export const SidebarAdSlot = () => {
    // <!-- FREESTAR D: 300x250,160x600,300x600 T: 300x250,160x600,300x600 M: 300x250,320x50,320x100 -->
    return <GenericAdSlot placement={"knowt_sticky_rightrail"} />;
};

// FreeStarVideoAdContainer
// FreeStarVideoAdContainer_Mobile
export const OutstreamVideoAd = ({ containerStyle = {} }) => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.freestar = window.freestar || {};
            window.freestar.queue = window.freestar.queue || [];
            // invoke video
            window.freestar.queue.push(function () {
                window.freestar.newVideo("FreeStarVideoAdContainer");
            });
        }

        return () => {
            if (typeof window !== "undefined") {
                window.freestar = window.freestar || {};
                window.freestar.queue.push(function () {
                    window.freestar.deleteVideo("FreeStarVideoAdContainer");
                });
            }
        };
    }, []);

    return (
        // <!-- FREESTAR D: 300x250 T: 300x250 M: 300x250 -->
        <div
            style={{
                ...containerStyle,
                backgroundColor: __DEV__ ? themeColors.neutral1 : "transparent",
                width: "100%",
                height: "100%",
                margin: "auto",
            }}>
            <div id="FreeStarVideoAdContainer">
                <div id="freestar-video-parent">
                    <div id="freestar-video-child"></div>
                </div>
            </div>
        </div>
    );
};

export default GenericAdSlot;
