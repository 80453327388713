import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useEffect, useState } from "react";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { useUserAlertsSelector } from "@knowt/syncing/hooks/guidedEvents/UserAlerts";

export const useDetectAdBlock = () => {
    const [adBlockDetected, setAdBlockDetected] = useState(false);
    const [isReported, setIsReported] = useState(false);

    const userAlerts = useUserAlertsSelector(state => state.userAlerts);
    const { latestEvent, closeLatestEvent } = useGuidedEvent(GUIDED_EVENTS.AD_BLOCKER_MIXPANEL);

    const { user } = useCurrentUser();

    const isAdblockPopupShown = userAlerts?.done?.includes?.(AllEventNames.ADBLOCKER);
    const shouldReport = latestEvent?.eventName === AllEventNames.ADBLOCKMX;

    useEffect(() => {
        // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
        const url = "https://a.pub.network/knowt-com/pubfig.min.js";
        fetch(url, {
            method: "HEAD",
            mode: "no-cors",
            cache: "no-store",
        })
            .then(() => {
                if (shouldReport && isAdblockPopupShown && !isReported) {
                    Mixpanel.track("Ad Block Conversion", { success: true });
                    setIsReported(true);
                    closeLatestEvent();
                }
            })
            .catch(() => {
                if (shouldReport && isAdblockPopupShown && !isReported) {
                    Mixpanel.track("Ad Block Conversion", { success: false });
                    setIsReported(true);
                    closeLatestEvent();
                }
                setAdBlockDetected(true);
            });
    }, [isAdblockPopupShown, shouldReport, user, closeLatestEvent, isReported]);

    return adBlockDetected;
};
